$(document).ready(function () {
    $('.products-slider').owlCarousel({
        // loop: true,
        margin: 10,
        nav: true,
        dots: false,
        autoHeight: true,
        navText: [
            '<span class="left-arrow"><i class=" fa fa-chevron-left"aria-hidden="true"></i></span>',
            ' <span class="right-arrow"> <i class=" fa fa-chevron-right"aria-hidden="true"></i></span>',
        ],
        responsive: {
            0: {
                items: 1,
                nav: false,
                dots: true,
            },
            600: {
                items: 2,
                nav: false,
                dots: true,
            },
            1000: {
                items: 3,
            },
        },
    });

    $('.copmany-slider').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        navText: [
            '<span class="left-arrow"><i class=" fa fa-chevron-left"aria-hidden="true"></i></span>',
            ' <span class="right-arrow"> <i class=" fa fa-chevron-right"aria-hidden="true"></i></span>',
        ],
        responsive: {
            0: {
                items: 2,
                nav: false,
                dots: true,
            },
            600: {
                items: 4,
                nav: false,
                dots: true,
            },
            1000: {
                items: 6,
            },
        },
    });
});
